.iCC__contentContainer {
  margin-top: 0.75rem !important;
}

.iCC__cHeading {
  margin: 0;
}

.iCC__cFeatures {
  padding-left: 16px;
}

.iCC__cFeatures li {
  margin-top: 5px;
  font-size: 13.5px;
  letter-spacing: 0.3px;
}
