:root {
  --primary: #023e8a;
  --primary-light: #0077b6;
  --primary-dark: #03045e;
  --secondary: #ec4884;
  --secondary-light: #f06196;
  --background-color: #03045e;
  --background-light: #023e8a;
  --text-bright: #e8fdf9;
  --text: #e2e2e2;
  --codefont: "Fira Code", monospace !important;
  --primaryFont: "Inter", "Roboto", sans-serif !important;
}

body {
  font-family: var(--primaryFont);
  color: var(--text);
  opacity: 0.8;
}

.dimText {
  color: var(--text);
  opacity: 0.8;
}

a {
  text-decoration: none;
}

li,
p {
  color: var(--text);
  opacity: 0.8;
  font-size: 15px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

h1 {
  font-size: 45px;
  font-weight: 700;
}

h2 {
  font-size: 35px;
  font-weight: 600;
}

h3 {
  font-size: 24px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 400;
}

/* Mui Global Customizations */

/* .MuiTooltip-tooltip {
  background: #023e8a !important;
} */
